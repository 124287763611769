<template>
  <report-queue-form v-model="obReportQueue" />
</template>

<script lang="ts">
import type { FirmData } from "@planetadeleste/vue-mc-gw";
import { Company } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel } from "vue-property-decorator";
import ReportQueueForm from "../ReportQueueForm.vue";

@Component({ components: { ReportQueueForm } })
export default class ReportQueue extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  get obReportQueue() {
    return this.obCompany.get("report_queue");
  }

  set obReportQueue(obData: string) {
    this.obCompany.set("report_queue", obData);
  }
}
</script>
