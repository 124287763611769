<template>
  <v-autocomplete
    v-model="queue"
    :items="items"
    :label="$t('select.queue')"
    dense
    item-text="value"
    item-value="id"
    outlined
    v-bind="$attrs"
    @change="onSelect"
  >
    <template #item="{ item }">
      {{ item.value }}
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";

@Component({
  components: { CompanyPreview },
})
export default class ReportQueueForm extends Vue {
  @VModel({ type: String }) queue!: string;

  obQueues = [
    { id: "higher", value: this.$t("reportjob.queue.higher") },
    { id: "high", value: this.$t("reportjob.queue.high") },
    { id: "medium", value: this.$t("reportjob.queue.medium") },
    { id: "low", value: this.$t("reportjob.queue.low") },
    { id: "lowest", value: this.$t("reportjob.queue.lowest") },
  ];

  //higher, high, medium, low, lowest
  get items() {
    return this.obQueues;
  }

  get selected(): string {
    return this.queue;
  }

  onSelect(sValue: string) {
    this.$emit("input", sValue);
  }
}
</script>
